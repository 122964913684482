import { useCreateSlot } from "../../api";
import { BsXLg } from "react-icons/bs";
import { useEditRotaContext } from "../../providers/EditRotaContext";
import { useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../../Components/Button";
import RenderedErrors from "../../Components/RenderedErrors";
import Label from "../../Components/Label";
import Dropdown from "../../Components/Dropdown";
import FlexCentered from "../../Containers/FlexCentered";
import Heading from "../../Components/Heading";
import { formatDate } from "../../utils/formattedDate";

function AddSlot({ forceOpen }) {
  const { rota, activeTeam, activeJob } = useEditRotaContext();

  const [isOpen, setIsOpen] = useState(forceOpen);
  const createSlotApi = useCreateSlot();

  const form = useForm();
  const errors = form.formState.errors;
  const { register } = form;
  register("day", {
    required: "Day required",
  });
  register("startHour", {
    required: "Start hours required",
  });
  register("startMin", {
    required: "Start mins required",
  });
  register("endHour", {
    required: "End hours required",
  });
  register("endMin", {
    required: "End mins required",
  });
  register("max", {});
  register("target", {
    required: "Goal required",
  });

  const daysList = useMemo(() => {
    let list = [];
    let currentDay = new Date(rota?.start);
    let endDay = new Date(rota?.end);
    while (currentDay <= endDay) {
      list.push({
        label: formatDate(currentDay, {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
        id: currentDay.getTime(),
        value: currentDay,
      });
      currentDay = new Date(currentDay.getTime() + 24 * 60 * 60 * 1000);
    }
    return list;
  }, [rota]);

  const startHours = useMemo(() => {
    let list = [];
    for (let i = 5; i <= 23; i++) {
      list.push({
        label: `${i}`.padStart(2, "0"),
        id: i,
        value: i,
      });
    }
    return list;
  }, []);

  const minsList = useMemo(() => {
    return [
      { id: "00", value: 0, label: "00" },
      { id: "15", value: 15, label: "15" },
      { id: "30", value: 30, label: "30" },
      { id: "45", value: 45, label: "45" },
    ];
  }, []);

  const digitList = useMemo(() => {
    let list = [];
    for (let i = 1; i <= 9; i++) {
      list.push({
        label: `${i}`,
        id: i,
        value: i,
      });
    }
    return list;
  }, []);

  const handleSubmit = (data) => {
    let start;
    let end;
    if (isFinite(data?.day?.value?.getTime())) {
      start = new Date(new Date(data?.day?.value));
      start.setHours(data.startHour?.value);
      start.setMinutes(data.startMin?.value);
      end = new Date(new Date(data?.day?.value));
      end.setHours(data.endHour?.value);
      end.setMinutes(data.endMin?.value);
    }

    createSlotApi.mutate({
      rotaRef: rota._id,
      teamRef: activeTeam._id,
      jobRef: activeJob._id,
      body: {
        start,
        end,
        max: data?.max?.value,
        target: data?.target?.value,
      },
    });
  };

  return (
    <div className="mt-4 w-full">
      {isOpen && (
        <form
          className="relative bg-gbfBlue-500 w-full py-4 pb-0"
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <Heading
            secondary
            className="text-center mb-8 text-xl text-gbfCream-50"
          >
            Add a Shift
          </Heading>
          <div className="md:grid md:grid-cols-[4rem_1fr] md:gap-x-2 mb-4 md:mb-8 px-2">
            <Label className="w-[4rem] whitespace-nowrap gap-x-1 text-gbfCream-50">
              Day <span className="text-red-300">*</span>
            </Label>
            <Controller
              control={form.control}
              name="day"
              render={({ field: { onChange, onBlur, value } }) => (
                <Dropdown
                  id="day"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className="w-full "
                  config={{
                    data: daysList,
                  }}
                  error={errors.day}
                />
              )}
            />
          </div>
          <div className="grid grid-cols-2 gap-y-4 md:gap-y-8 px-2">
            <div className="md:grid md:grid-cols-[4rem_1fr] md:gap-x-2">
              <Label className="w-[4rem] text-gbfCream-50">
                Start <span className="text-red-300">*</span>
              </Label>
              <FlexCentered row className="w-fit gap-x-2">
                <Controller
                  control={form.control}
                  name="startHour"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropdown
                      id="startHour"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      className="w-[4rem]"
                      config={{
                        data: startHours,
                      }}
                      error={errors.startHour}
                    />
                  )}
                />
                <div className="text-xl text-gbfCream-50">:</div>
                <Controller
                  control={form.control}
                  name="startMin"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropdown
                      id="startMin"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      className="w-[4rem]"
                      config={{
                        data: minsList,
                      }}
                      error={errors.startMin}
                    />
                  )}
                />
              </FlexCentered>
            </div>
            <div className="md:grid md:grid-cols-[4rem_1fr] md:gap-x-2">
              <Label className="w-[4rem] text-gbfCream-50">
                End <span className="text-red-300">*</span>
              </Label>
              <FlexCentered row className="gap-x-2 w-fit">
                <Controller
                  control={form.control}
                  name="endHour"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropdown
                      id="endHour"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      className="w-[4rem]"
                      config={{
                        data: startHours,
                      }}
                      error={errors.endHour}
                    />
                  )}
                />
                <div className="text-xl text-gbfCream-50">:</div>
                <Controller
                  control={form.control}
                  name="endMin"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropdown
                      id="endMin"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      className="w-[4rem]"
                      config={{
                        data: minsList,
                      }}
                      error={errors.endMin}
                    />
                  )}
                />
              </FlexCentered>
            </div>
            <div className="md:grid md:grid-cols-[4rem_1fr] md:gap-x-2">
              <Label className="w-[4rem] text-gbfCream-50">Max</Label>

              <Controller
                control={form.control}
                name="max"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Dropdown
                    id="max"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    className="w-[4rem]"
                    config={{
                      data: digitList,
                    }}
                    error={errors.max}
                  />
                )}
              />
            </div>
            <div className="md:grid md:grid-cols-[4rem_1fr] md:gap-x-2">
              <Label className="w-[4rem] text-gbfCream-50">
                Goal <span className="text-red-300">*</span>
              </Label>

              <div className="flex flex-row items-start w-full gap-x-2">
                <Controller
                  control={form.control}
                  name="target"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropdown
                      id="target"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      className="w-[4rem]"
                      config={{
                        data: digitList,
                      }}
                      error={errors.target}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <FlexCentered col className="col-span-2 mt-8">
            <RenderedErrors
              errors={errors}
              apiError={createSlotApi.error}
              className="bg-gbfCream-50 mb-2"
            />
            <Button
              primary
              loading={createSlotApi.isLoading}
              className="w-full"
            >
              Save
            </Button>
          </FlexCentered>
          {!forceOpen && (
            <div className="absolute top-[5px] right-[5px] bg-gbfCream-50 rounded-full p-1 cursor-pointer hover:scale-105 hover:text-red-900">
              <BsXLg onClick={() => setIsOpen(false)} />
            </div>
          )}
        </form>
      )}
      {!isOpen && (
        <FlexCentered col>
          <Button primary onClick={() => setIsOpen(true)}>
            Add Slot
          </Button>
        </FlexCentered>
      )}
    </div>
  );
}
export default AddSlot;
