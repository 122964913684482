import { formatTime, formatDate } from "../../utils/formattedDate";

function DayNav({ rota, blockWidth, topBarHeight, visibleBlockRange }) {
  const getDayPosition = (firstBlock, lastBlock) => {
    return {
      top: "5px",
      left: `${firstBlock * blockWidth}px`,
      width: `${(lastBlock - firstBlock) * blockWidth}px`,
      height: "50px",
    };
  };
  const getTimePosition = (block) => {
    return {
      top: "50px",
      left: `${block * blockWidth}px`,
      height: "50px",
    };
  };

  const renderedTimes = [];
  const dayLines = [];

  const renderedDays = rota.days.map((day) => {
    if (day.earliestBlock > visibleBlockRange[1]) return undefined;
    if (day.lastBlock < visibleBlockRange[0]) return undefined;
    const { earliestBlock, lastBlock, earliestStart } = day;
    dayLines.push(
      <div
        key={earliestBlock}
        className="absolute bg-gray-400 w-[2px] opacity-20"
        style={{
          top: 0,
          bottom: 0,
          left: `${(earliestBlock - 1) * blockWidth}px`,
        }}
      />
    );

    for (let i = 0; i <= lastBlock - earliestBlock; i++) {
      const currentTime = new Date(
        new Date(earliestStart).getTime() + i * 1000 * 60 * 15
      );

      const block = earliestBlock - 1 + i;
      if (block < visibleBlockRange[0]) continue;
      if (block > visibleBlockRange[1]) continue;

      if (currentTime.getMinutes() === 0) {
        const timeString = formatTime(currentTime, {
          hour: "2-digit",
        });

        renderedTimes.push(
          <div
            key={earliestBlock + i}
            className="absolute w-fit -translate-x-1/2"
            style={getTimePosition(block)}
          >
            {timeString}
          </div>
        );
      }
    }

    return (
      <div
        key={day.day}
        className="absolute text-center"
        style={getDayPosition(earliestBlock, lastBlock)}
      >
        {formatDate(new Date(day.earliestStart))}
      </div>
    );
  });

  return (
    <>
      {renderedDays}
      {renderedTimes}
      {dayLines}
    </>
  );
}
export default DayNav;
