
export function formatTime(d, customOptions = {
    hour: "2-digit", minute: "2-digit"
}) {
    const options = {
        timeZone: "Europe/London",
        ...customOptions
    }
    return formatDateTime(d, options)
}

export function formatDate(d, customOptions = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "2-digit",
}) {
    const options = {
        timeZone: "Europe/London",
        ...customOptions
    };
    return formatDateTime(d, options);
}

function formatDateTime(d, options) {

    const formatter = new Intl.DateTimeFormat("en-GB",
        options
    );
    return formatter.format(d);
}