import P from "../../Components/P";
import Heading from "../../Components/Heading";
import FlexCentered from "../../Containers/FlexCentered";
import { formatTime } from "../../utils/formattedDate";

function SlotPopUp({ activeSlot, rota }) {
  if (!activeSlot) return;

  const job = rota.jobs[rota.jobsIndex[activeSlot.job]]?.name;
  const start = formatTime(new Date(activeSlot.start));
  const end = formatTime(new Date(activeSlot.end));

  const renderedVols = activeSlot.vols.map((vol, i) => {
    return <li key={i}>{vol}</li>;
  });

  return (
    <div className="absolute bg-gbfYellow-500 top-0 right-0 h-fit w-fit px-4 pt-2 pb-4 z-50">
      <FlexCentered col className="border-b-2 w-full px-2 pb-1 mb-2">
        <Heading tertiary>{job}</Heading>
        <div>{`${start} - ${end}`}</div>
      </FlexCentered>
      <div className="grid grid-cols-[auto_auto] gap-x-2 bg-gbfYellow-200 rounded shadow p-2 mb-4">
        <P>Max:</P>
        <P>{activeSlot.max || "-"}</P>
        <P>Target:</P>
        <P>{activeSlot.target}</P>
      </div>
      {activeSlot.vols.length > 0 && (
        <div>
          <Heading tertiary>Volunteers</Heading>
          <ul className="list-disc pl-4">{renderedVols}</ul>
        </div>
      )}
    </div>
  );
}
export default SlotPopUp;
